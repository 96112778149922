.omega-button.approve {
    border-color: #238d45;
    background-color: #167e38;
    color: #fff;
}

.omega-button.approve:focus {
    border-color: #1a6533;
    box-shadow: 0 0 8px #2caa55;
}

.omega-button.approve:hover:not([disabled]) {
    border-color: #1a6533;
    background-color: #228141;
}
