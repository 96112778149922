omega-filter-control {
    display: block;
    padding: 5px;
}

.advanced-filters {
    padding: 0;
    margin: 0;
    list-style: none;
}

.advanced-filters input {
    display: block;
}

.advanced-filters li input {
    display: inline-block;
}

.omega-table details {
    margin: 10px;
}

.omega-table summary h3 {
    display: inline-block;
    cursor: pointer;
}

.date-filter input {
    display: inline-block;
    clear: none;
}

.advanced-filters .radio-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.advanced-filters .radio-list li {
    padding: 5px;
}
