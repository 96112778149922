.omega_container_droppable {
    position: relative !important; /* was being overridden to static */
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    background: #f2f2f2;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    margin: 20px 0;
    padding: 5px;
    min-height: 60px;
}

.omega_tile_draggable {
    position: relative;
    display: block;
    margin: 5px;
    width: 225px;
    height: 45px;
    padding: 5px;
    background-color: #fcfcfc;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    cursor: move;
}

.omega_tile_fixed_position {
    width: 275px;
}

.omega_tile_draggable:before,
.omega_tile_draggable:after {
    box-sizing: border-box;
    transition:
        background-color 400ms ease-out,
        border-color 400ms ease-out,
        color 400ms ease-out;
}

.omega_tile_draggable.active {
    background: #eee;
    border: 1px dashed #a6a6a6;
}

.omega_tile_draggable[data-type='payment information']:not(.active) {
    border-left: 6px solid #19c466;
}

.omega_tile_draggable[data-type='beneficiary information']:not(.active) {
    border-left: 6px solid #00bde9;
}

.omega_tile_draggable[data-type='domestic intermediary']:not(.active) {
    border-left: 6px solid #a5a5a5;
}

.omega_tile_draggable[data-type='filler']:not(.active) {
    background:
        linear-gradient(#fff, #fff) padding-box,
        repeating-linear-gradient(45deg, #ffffff, #ffffff 2px, #ffffff 2px, #6e6e6e 4px) border-box;
    border-left: 6px solid transparent;
}

.omega_tile_draggable.omega_tile_default {
    border-left: 6px solid rgb(104, 93, 93);
}

.omega_tile_draggable.dragging {
    background: #e6eaee;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.7);
    opacity: 0.925;
}

.omega_tile_draggable.dragging .tile_order {
    display: none;
}

.omega_tile_draggable.active .tile_order {
    display: none;
}

.tile_position_count_input {
    position: absolute;
    top: 0;
    left: 218px;
    width: 50px;
    height: 43px;
    border: none;
    border-left: 1px solid #d6d6d6;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
}

.tile_position_button {
    padding: 0;
    border: none;
    background: none;
    padding: 5px;
    margin: 5px;
    position: absolute;
    right: 3px;
}

.tile_position_button::after {
    position: absolute;
    width: 0;
    right: 1px;
    top: 3px;
    height: 0;
    border-style: solid;
    border-color: #4b4742 transparent;
    content: '';
}

.tile_position_button[disabled='true']::after {
    border-color: rgb(189, 183, 183) transparent;
}

.tile_position_button.add_button {
    top: 3px;
}

.tile_position_button.add_button::after {
    border-width: 0 4px 4px;
}

.tile_position_button.subtract_button {
    top: 22px;
}

.tile_position_button.subtract_button::after {
    border-width: 4px 4px 0;
}

.tile_position_count_input::-webkit-inner-spin-button,
.tile_position_count_input::-webkit-outer-spin-button {
    appearance: none;
}

.tile_order {
    position: absolute;
    top: 8px;
    font-weight: 300;
    font-size: 12px;
}

.omega_tile_delimited .tile_order {
    right: 5px;
}

.omega_tile_fixed_position .tile_order {
    right: 60px;
}

.omega_tile_draggable[data-active='false'] .tile_order {
    display: none;
}

.tile_title {
    font-size: 14px;
    font-weight: 600;
    margin: 3px 0 0 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tile_title span {
    font-weight: 400;
}

.tile_subtitle {
    width: 200px;
    font-size: 11px;
    margin: 0 0 0 3px;
    color: grey;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tile_remove_button {
    position: absolute;
    top: 25px;
    right: 0;
    font-weight: 300;
    font-size: 12px;
    color: rgb(146, 141, 141);
    font-size: 13px;
    border: none;
    display: none;
}

.omega_tile_draggable .tile_remove_button.show {
    display: block;
}

.omega_tile_draggable.dragging .tile_remove_button {
    opacity: 0;
}

.omega_tile_draggable.active .tile_remove_button {
    opacity: 0;
}

.omega_tile_fixed_position .tile_remove_button {
    right: 55px;
}
