@import url('./table-core.css');
@import url('./table-filters.css');
@import url('./table-control-bar.css');
@import url('./table-headers.css');
@import url('./table-body.css');
@import url('./table-pagination.css');
@import url('./table-datatypes.css');

.omega-table table {
    overflow-x: auto;
}

.omega-table .radio-list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}

.omega-table .radio-list li > * {
    display: inline-block;
}

.omega-table :not(form).ng-invalid {
    border: 1px solid #cc1010;
    background-color: rgba(204, 16, 16, 0.03);
    border-radius: 3px;
}

.omega-table :not(form).ng-pristine {
    border: 1px solid rgb(199, 199, 199);
    background-color: #fff;
    border-radius: 3px;
}

.omega-table .error-indicator {
    display: none;
}

.omega-table tr.has-errors .error-indicator {
    display: inline;
}

.omega-table a {
    text-decoration: underline;
}

.omega-is-loading {
    opacity: 0.7;
    transition: opacity 0.3s;
}
