.omega-toggle {
    width: 50px;
    height: 28px;
    background-color: #ccc;
    border-radius: 14px;
}

.omega-toggle-indicator {
    display: block;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 12px;
    transform: translate(2px, 2px);
    transition: transform 0.2s;
}

.omega-toggle:not(:disabled):hover {
    cursor: pointer;
}

.omega-toggle:focus {
    outline: none;
}

.omega-toggle-checkbox:focus + .omega-toggle,
.omega-toggle:focus {
    box-shadow: 0 1px 4px 0;
    outline: none;
}

.omega-toggle-checkbox:focus + .omega-toggle > .omega-toggle-indicator {
    box-shadow: 0 1px 4px 0;
}

.omega-toggle-checkbox:disabled + .omega-toggle {
    opacity: 0.6;
    pointer-events: none;
}

.omega-toggle-checkbox:checked + .omega-toggle {
    background-color: var(--omega-primary);
}

.omega-toggle-checkbox:checked + .omega-toggle > .omega-toggle-indicator {
    transform: translate(24px, 2px);
}
