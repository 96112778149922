.omega-table-form {
    padding: 0;
    margin: 0;
    border: 0;
}

omega-table-legacy details {
    display: none;
}

omega-table-legacy {
    position: relative;
    display: block;

    /* padding: 0 0 31px; */
}

omega-table-legacy .sticky-header {
    position: sticky;
    top: -1px;
    height: 31px;
    padding: 5px;
    margin: 0 -1px -31px;
    background-color: white;
    font-weight: bold;
    text-align: left;
}

omega-table-legacy .omega-table-title {
    z-index: -1; /* to slide behind the sticky control bar */
    top: 45px;
    padding: 10px 20px;
    margin: 0;
    background-color: white;
    font-size: 18px;
    font-weight: 600;
}

omega-table-legacy table {
    width: 100%;
    margin: 0;
    border-collapse: collapse;
    font-size: 13px;
}

omega-table-legacy .table-footer {
    position: sticky;
    top: 0;
    bottom: -1px;
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: #f0f0f0;
}

.omega-table-empty-state {
    display: flex;
    height: 50vh;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.omega-table-empty-state span {
    font-size: 18px;
}
