dialog::backdrop {
    background: rgba(0, 0, 0, 0.5);
}

.omega-dialog {
    position: absolute;
    top: 5%;
    min-width: 600px;
    max-width: 900px;
    padding: 0;
    border: none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0;
    color: var(--omega-base-font-color);
}

.omega-dialog-title {
    flex: 1 1;
    padding: 15px;
    margin: 0;
    border-bottom: 1px solid var(--omega-divider-color);
    font-size: 18px;
}

.omega-dialog-body {
    padding: 15px;
}

.omega-dialog omega-button-bar {
    display: block;
    width: 100%;
    flex: 1 1;
    border-top: 1px solid var(--omega-divider-color);
    text-align: left;
}
