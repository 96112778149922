.omega-form-field {
    position: relative;
    display: flex;
    max-width: 800px;
    flex-flow: row wrap;
    margin: 15px 0;
}

.omega-form-label-box {
    min-width: 200px;
}

.omega-form-label-box > label::after {
    content: ':';
}

.omega-form-input-box {
    position: relative;
    display: block;
    min-width: 250px;
    flex: 3 1;
}

.omega-form-field input:not([type='checkbox']):not([type='radio']) {
    width: 100%;
    height: 32px;
    padding: 3px 12px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 3px;
    font-size: 13px;
    line-height: 18px;
}

.omega-form-field input:focus {
    border: 1px solid #66afe9;
    box-shadow: 0 2px 4px 0 rgba(102, 175, 233, 0.6);
}

.omega-form-field input:disabled,
.omega-form-field select:disabled,
.omega-form-field button:disabled {
    opacity: 0.6;
}

.omega-form-field [aria-invalid='true'] {
    border-color: #cc1010;
    background-color: rgba(204, 16, 16, 0.03);
}

.omega-form-field .omega-multiple-select[aria-invalid='true'] .omega-multiple-select-button {
    border: 1px solid #cc1010;
    background-color: rgba(204, 16, 16, 0.03);
}

.omega-required-field-indicator {
    color: #ee3a3b;
}

.omega-form-input-box .has-annotation,
.omega-form-input-box .has-lookup {
    display: flex;
}

.omega-form-input-box .has-lookup input {
    width: 265px;
}

.omega-form-input-box .has-annotation input {
    width: 240px;
}

.omega-form-input-box .has-lookup input,
.omega-form-input-box .has-annotation input {
    border-right: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.omega-form-input-annotation {
    width: 60px;
    margin: 0;
    border: 1px solid #ccc;
    background-color: #eee;
    border-radius: 0 3px 3px 0;
    font-size: 13px;
    font-weight: 400px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
}

.omega-form-search-button {
    width: 35px;
    margin: 0;
    border: 1px solid #ccc;
    background-color: #eee;
    border-radius: 0 3px 3px 0;
    font-size: 13px;
    text-align: center;
    vertical-align: middle;
}
