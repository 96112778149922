.omega-multiple-select[aria-expanded='true'] {
    position: relative;
    height: 32px;
    background-color: white;
    border-radius: 3px 3px 0 0;
}

.omega-multiple-select-button {
    text-overflow: ellipsis;
    position: relative;
    width: 100%;
    height: 32px;
    padding: 6px 10px;
    margin: 0 auto;
    border: 1px solid rgb(199, 199, 199);
    background-color: #fff;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    text-align: left;
    transition:
        border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
}

.omega-multiple-select-button::after {
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 0;
    height: 0;
    margin-top: -2px;
    border-width: 6px 6px 0;
    border-style: solid;
    border-color: #4b4742 transparent;
    content: '';
}

.omega-multiple-select[aria-expanded='true'] .omega-multiple-select-button {
    display: none;
    border: none;
    box-shadow: none;
}

.omega-multiple-select[aria-expanded='false'] > :not(.omega-multiple-select-button) {
    display: none;
}

.omega-multiple-select-search-wrapper {
    position: relative;
    top: 0;
    width: 100%;
}

.omega-multiple-select-select-all-label.omega-checkbox-label {
    border-bottom: 1px solid gainsboro;
}

.omega-multiple-select-no-results {
    height: 32px;
    line-height: 32px;
    text-align: center;
}

.omega-multiple-select-search {
    width: 100%;
    height: 32px;
    padding: 6px 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition:
        border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
}

.omega-multiple-select-search-icon {
    position: absolute;
    top: 8px;
    right: 12px;
    color: #888;
    font-size: 14px;
}

.omega-multiple-select-list[role='listbox'] {
    position: absolute;
    z-index: 3;
    top: 33px;
    left: 0;
    width: 100%;
    max-height: 192px;
    padding: 0;
    margin: 0;
    border: 0;
    border-right: 1px solid rgb(214, 209, 209);
    border-bottom: 1px solid rgb(214, 209, 209);
    border-left: 1px solid rgb(214, 209, 209);
    background-color: white;
    border-radius: 0 0 3px 3px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    list-style: none;
    opacity: 0.99;
    overflow-y: scroll;
}

.omega-multiple-select-list-option:focus {
    border: 1px solid #66afe9;
    box-shadow: 0 2px 4px 0 rgba(102, 175, 233, 0.6);
}

.omega-form-input-box .omega-multiple-select [aria-selected='true'] {
    background: rgb(228, 222, 222);
}

.omega-multiple-select .omega-checkbox-label {
    width: 100%;
}

.omega-multiple-select-list label {
    margin: 0;
    padding: 5px 0 5px 0;
}

.omega-single-select-override .omega-multiple-select-list label {
    padding-left: 5px;
}

.omega-multiple-select-required {
    border: 1px solid red;
    background-color: rgba(204, 16, 16, 0.03);
}

.omega-multiple-select-error {
    border: 1px solid #c10f00;
    background-color: rgba(204, 16, 16, 0.03);
    box-shadow: 0 0 0 1px rgb(193, 15, 0, 0.2);
    transition: all 200ms cubic-bezier(0, 0, 0.29, 0.75);
}
