@import url('./button-approve.css');
@import url('./button-reject.css');
@import url('./button-primary.css');
@import url('./button-secondary.css');
@import url('./button-icon.css');
@import url('./button-with-errors.css');

.omega-button {
    min-width: 90px;
    padding: 10px 20px;
    margin: 8px;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    font-size: 1em;
    font-weight: 400;
    line-height: 16px;
    outline: none;
}

.omega-button[disabled] {
    opacity: 0.65;
}

.omega-button:enabled {
    cursor: pointer;
}

.omega-button.small {
    min-width: 0;
    padding: 10px;
    margin: 10px 2px;
    line-height: 8px;
}

.omega-button-label {
    text-align: center;
}
