.omega-button.icon {
    padding: 10px 8px;
    border: 1px solid transparent;
    background-color: #fff;
    color: #0070b7;
}

.omega-button.icon i {
    line-height: 16px;
}

.omega-button.icon i + .omega-button-label {
    margin-left: 0.5ex;
}

.omega-button.icon:not(.labelled) {
    min-width: 1px;
    padding: 10px 12px;
    margin-right: 0;
    margin-left: 0;
}

.omega-button.icon:focus,
.omega-button.icon:hover:not(:disabled) {
    background-color: #e6f0fa;
}
