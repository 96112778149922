.pagination-message {
    font-weight: bold;
}

.pagination-nav {
    display: flex;
    padding: 0;
    margin: 0;
}

.pagination-controls {
    display: flex;
}

.pagination-nav ol {
    display: inline-block;
    padding: 0;
    margin: 0;
}

.pagination-nav li {
    display: inline-block;
    margin: 0;
    list-style: none;
}

.omega-table .pagination-nav a {
    display: block;
    min-width: 20px;
    padding: 0 10px;
    border: 1px solid #ddd;
    border-right-width: 0;
    background-color: white;
    color: #337ab7;
    cursor: pointer;
    line-height: 30px;
    text-align: center;
    text-decoration: none;
}

.pagination-nav a:hover {
    border-color: #ddd;
    background-color: #eee;
    color: #22527b;
}

.pagination-nav li {
    font-weight: bold;
}

.pagination-nav .omega-disabled {
    font-weight: normal;
    opacity: 0.5;
}

.omega-table .pagination-nav [aria-disabled='true'] {
    color: inherit;
    cursor: default;
    pointer-events: none;
}

.omega-table .pagination-nav [aria-current='page'] {
    background-color: #337ab7;
    color: #fff;
    cursor: default;
    pointer-events: none;
}

.pagination-nav li:first-child a {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 5px;
}

.pagination-nav li:last-child a {
    border-right-width: 1px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

.table-footer select {
    height: 30px;
    margin: 0 10px;
}
