@import url('./form-group.css');
@import url('./buttons/buttons.css');
@import url('./checkbox.css');
@import url('./inputs.css');
@import url('./select.css');
@import url('./date-range.css');
@import url('./amount-range.css');
@import url('./toggle.css');
@import url('./combobox.css');
@import url('./multiple-select.css');
@import url('./autocomplete.css');
@import url('./datepicker.css');
@import url('./address.css');
@import url('./table-filters.css');

.omega-field-parts {
    display: inline-block;
}

.omega-form-field .omega-form-field {
    margin: 10px 0;
}
