[role='listbox'] .omega-account-name {
    display: block;
    max-width: 200px;
    padding-right: 15px;
    float: left;
    font-weight: bold;
}

[role='listbox'] .omega-account-number {
    display: block;
    clear: both;
    float: left;
}

[role='listbox'] .omega-account-balance {
    display: block;
    float: right;
}
