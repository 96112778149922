.omega-table-table tr:nth-of-type(2n) {
    background-color: #fafbfc;
    border-top: 1px solid #e6eaee;
    border-bottom: 1px solid #e6eaee;
}

omega-table-legacy .select-column {
    width: 30px;
    text-align: center;
}

.omega-table-table td {
    padding: 15px 10px;
    line-height: 16px;
    font-size: 13px;
    color: #3c3c3c;
}
