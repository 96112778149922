.omega-form-field input.address-street {
    width: 300px;
    margin-bottom: 2px;
}

.omega-form-field input.address-city {
    width: 140px;
}

.omega-form-field input.address-state {
    width: 50px;
    margin: 0 10px;
}

.omega-form-field input.address-zip {
    width: 90px;
}
