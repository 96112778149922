.omega-ach-company-lookup-list > li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.group-1 {
    max-width: 300px;
    margin-right: 50px;
}

.group-2 {
    min-width: 200px;
    height: auto;
    margin: auto 0;
}

.omega-lookup-ach-company-name {
    display: block;
    margin-bottom: 5px;
    color: var(--omega-primary);
    font-size: 18px;
}

.omega-lookup-ach-company-id {
    display: block;
    margin-bottom: 5px;
    color: var(--omega-text-secondary);
    font-size: 18px;
    text-align: right;
}

.omega-lookup-ach-company-description {
    display: inline-block;
    padding: 0 10px 0 0;
    margin: 0 10px 8px 0;
    border-right: 1px solid var(--omega-text-secondary);
    color: var(--omega-text-secondary);
}

.omega-lookup-ach-company-sec-code-list {
    display: inline;
    padding: 0;
    margin: 0;
    color: var(--omega-text-secondary);
    list-style: none;
}

.omega-lookup-ach-company-account-type-list {
    display: inline;
    padding: 0 0 0 10px;
    margin: 0 0 0 10px;
    border-left: 1px solid var(--omega-text-secondary);
    color: var(--omega-text-secondary);
    list-style: none;
    text-align: right;
}

.omega-lookup-ach-company-sec-code-list > li,
.omega-lookup-ach-company-account-type-list > li {
    display: inline;
}

.omega-lookup-ach-company-sec-code-list > li {
    padding: 3px 5px;
    margin: 0 0 0 10px;
    background-color: #ddd;
    border-radius: 10px;
}

.omega-lookup-ach-company-account-type-list > li:not(:last-child)::after {
    content: ', ';
}

.omega-lookup-ach-company-discretionary-data {
    display: inline-block;
    color: var(--omega-text-secondary);
}

.omega-lookup-ach-company-batch-balance-requirements {
    display: inline;
    color: var(--omega-text-secondary);
    text-align: right;
}
