.omega-button.primary {
    border: 1px #2d6da3;
    background-color: #0070b7;
    color: #fff;
}

.omega-button.primary:focus {
    border-color: #204d73;
    box-shadow: 0 0 8px 0 #337ab7;
}

.omega-button.primary:hover:not([disabled]) {
    border-color: #204d73;
    background-color: #285f8f;
}
