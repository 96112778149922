.omega-table-filters {
    position: sticky;
    top: 45px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    border-top: 1px #ccc;
    background-color: #f1f8fd;
}

.omega-table-filter-wrapper {
    display: flex;
    flex: 500px 20 1;
    flex-wrap: wrap;
}

.omega-table-filter {
    width: 300px;
    margin: 0;
}

.omega-table-button-wrapper {
    display: flex;
    flex: 110px 1 1;
    flex-flow: column wrap;
    align-self: center;
    padding-top: 18px;
    margin: 10px;
    direction: rtl;
}

.omega-table-button-wrapper omega-button-legacy button {
    width: 100%;
}

.omega-table-filters .omega-form-field {
    flex-flow: column;
    margin: 10px;
}

.omega-table-filters .omega-form-field label {
    color: #656a81;
    font-size: 14px;
    font-weight: 400;
}

.omega-table-filters .omega-form-field label::after {
    content: '';
}

@media only screen and (max-width: 900px) {
    .omega-table-button-wrapper {
        padding-top: 0;
    }
}
