.omega-form-field .omega-amount-range-from-to {
    display: flex;
    align-items: baseline;
    clear: both;
}

.omega-form-field .omega-amount-range-from-to input {
    width: auto;
    flex: 10 1;
    align-content: space-between;
}

.omega-form-field .omega-amount-range-to-label {
    flex: 2 1;
    margin: 0 5px;
    text-align: right;
}

.omega-amount-range .omega-select {
    text-transform: capitalize;
}
