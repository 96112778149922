.omega-table-table th,
.omega-table-table thead td {
    position: sticky;
    z-index: 1;
    height: 45px;
    padding: 0 10px;
    border-width: 0;
    border-style: solid;
    font-size: 14px;
    background-color: #e6e6e6;
    box-shadow: inset 0 -1px 0 #ccc;
    color: #464646;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
}

.omega-table.omega-table-has-caption th,
.omega-table.omega-table-has-caption thead td {
    top: 64px; /* to make space for the sticky control bar and slide from behind header */
}

.omega-table th.sortable {
    cursor: pointer;
}

.omega-table th.sortable[aria-sort='ascending']::after {
    content: '\f0de';
}

.omega-table th.sortable[aria-sort='descending']::after {
    content: '\f0dd';
}

.omega-table th.sortable::after {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 2px;
    background-size: 1em 1em;
    content: '\f0dc';
    font-family: FontAwesome;
    opacity: 0.5;
    vertical-align: top;
}

.omega-table-sort-button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    cursor: pointer;
    opacity: 0.01;
}
