.omega-table-controls {
    display: flex;
    min-height: 45px;
    align-items: center;
    padding: 0 18px;
}

.omega-table-filters.omega-table-controls-panel {
    z-index: 3;
    max-height: 0;
    padding: 0 10px;
    transition: max-height 0.1s ease-out;
}

.omega-table-filters-hidden {
    overflow: hidden;
}

.omega-table-controls span {
    padding-left: 5px;
    color: #318ec6;
    font-size: 14px;
    font-weight: 500;
}

.omega-table .omega-table-controls {
    position: sticky;
    z-index: 1;
    top: 0;
    width: 100%;
    padding: 5px 0 5px 18px;
    border: none;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    background-color: #f9f9f9;
    transition: 0.4s;
}

.omega-table-controls-filter-btn {
    cursor: pointer;
}

.omega-table.omega-table-has-caption .omega-table-controls {
    top: 20px; /* To leave room for the slide-from-behind sticky header */
}

.omega-table .quick-filter {
    width: 400px;
}
