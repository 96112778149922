.omega-icon-column {
    text-align: center;
}

.omega-icon-column i {
    cursor: pointer;
    font-size: 20px;
    min-width: 44px;
}

.omega-popover {
    min-width: 400px;
}

.omega-popover-template-label {
    min-width: 100px;
    float: left;
}

.omega-popover-template-data {
    float: right;
}
