.omega-button:disabled.has-errors {
    padding: 0;
    background: none;
    opacity: 1;
}

.omega-button.has-errors > .omega-button-label {
    display: inline-block;
    padding: 10px 20px;
    margin: 0;
    background-color: #337ab7;
    font-size: 1em;
    line-height: 16px;
    opacity: 0.65;
}

.omega-button.has-errors > i {
    display: inline-block;
    width: 35px;
    padding: 10px 0;
    margin: 0;
    background-color: #337ab7;
    font-size: 1em;
    line-height: 16px;
    text-align: center;
}
