.omega-datepicker {
    position: absolute;
    display: inline-block;

    --datepicker-day-button-size: 30px;
    --datepicker-month-button-width: 75px;
    --datepicker-month-button-height: 45px;
}

.omega-datepicker.is-open {
    z-index: 3;
    width: 280px;
    padding: 0px 10px 10px 10px;
    border: 1px solid var(--omega-input-default-border);
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

[aria-expanded='false'] .omega-datepicker-calendar,
.omega-datepicker-calendar[aria-expanded='false'] {
    display: none;
}

.omega-datepicker-descriptor {
    position: absolute;
    top: 0;
    left: 10px;
    line-height: 32px;
}

.omega-datepicker-input {
    height: 32px;
    padding: 6px 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 3px;
    min-width: 120px;
}

.omega-datepicker .omega-datepicker-main-input {
    cursor: pointer;
    width: 280px;
    position: relative;
    margin: 0 auto;
}

.omega-datepicker .omega-datepicker-main-input::after {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 0;
    height: 0;
    margin-top: -2px;
    border-width: 6px 6px 0;
    border-style: solid;
    border-color: #4b4742 transparent;
    content: '';
}

.omega-datepicker .omega-datepicker-main-input[disabled='true'] {
    opacity: 0.65;
    cursor: default;
}

.omega-datepicker .omega-datepicker-main-input-type {
    white-space: nowrap;
}

.omega-datepicker .omega-datepicker-main-input-display {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.omega-datepicker .omega-datepicker-main-input-value {
    margin-right: 10px;
    color: rgb(109, 107, 107);
    white-space: nowrap;
}

.omega-datepicker.is-open .omega-datepicker-toggle-button {
    display: none;
    background-color: #fff;
}

.omega-datepicker.is-open .omega-datepicker-main-input {
    display: none;
}

.omega-form-field .omega-datepicker:not(.is-open) input {
    width: 280px;
    padding-right: 24px;
    text-align: center;
}

.omega-form-field .omega-datepicker.is-open input {
    margin: 5px;
    padding: 0 10px;
    font-size: 12px;
    text-align: left;
    /* temp: better way to align and size inputs */
    width: 95%;
}

.omega-datepicker-hint-text {
    display: block;
    text-align: center;
    visibility: hidden;
}

.omega-datepicker.is-open .omega-datepicker-hint-text {
    visibility: visible;
}

.omega-datepicker-calendar-header {
    font-weight: 600;
}

.omega-datepicker-calendar-label {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
}

.omega-datepicker-calendar-label:hover {
    color: var(--omega-primary);
    cursor: pointer;
}

.omega-datepicker th {
    color: rgb(179, 179, 179);
    font-size: 11px;
    font-weight: 400;
    text-align: center;
}

.omega-datepicker-calendar {
    position: relative;
    height: 100px;
}

.omega-datepicker-month-selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
}

.omega-datepicker-last-month-button,
.omega-datepicker-next-month-button {
    width: 20px;
    height: 20px;
    padding: 5px;
    border: none;
    appearance: none;
    background: var(--omega-dropdown-arrow) right 4px center no-repeat #fff;
    background-color: transparent;
    background-size: 12px 12px;
}

.omega-datepicker-last-month-button {
    margin-right: 10px;
    transform: rotate(90deg);
}

.omega-datepicker-next-month-button {
    transform: rotate(-90deg);
}

.omega-datepicker td {
    padding: 0;
}

.omega-datepicker-day {
    width: 30px;
    width: var(--datepicker-day-button-size);
    height: var(--datepicker-day-button-size);
    margin: 0 4px;
    border: none;
    appearance: none;
    background-color: transparent;
    line-height: var(--datepicker-day-button-size);
    text-align: center;
}

.omega-datepicker-day:hover {
    border: 1px solid var(--omega-primary);
    border-radius: 5px;
}

.omega-datepicker-day-cell {
    position: relative;
}

.omega-datepicker-day-cell.is-selected button,
.omega-datepicker-day-cell.is-selection-start button,
.omega-datepicker-day-cell.is-selection-end button {
    background-color: var(--omega-primary);
    border-radius: 7px;
    color: var(--omega-primary-foreground);
}

.omega-datepicker-day-cell.is-selection-middle {
    background-color: var(--omega-calendar-selection-color);
    color: var(--omega-primary);
}

.omega-datepicker-day-cell.is-selection-start::before,
.omega-datepicker-day-cell.is-selection-end::before {
    position: absolute;
    z-index: -1;
    right: 0;
    display: block;
    width: 20px;
    height: 30px;
    background-color: var(--omega-calendar-selection-color);
    content: '';
}

.omega-datepicker-day-cell.is-selection-start.is-selection-end::before {
    display: none;
}

.omega-datepicker-day-cell.is-selection-start::before {
    right: 0;
}

.omega-datepicker-day-cell.is-selection-end::before {
    left: 0;
}

.omega-datepicker-last-month,
.omega-datepicker-next-month {
    color: var(--omega-grayed-out);
}

.omega-datepicker:not(.is-open) .omega-datepicker-list {
    display: none;
}

.omega-datepicker-list {
    padding: 0;
    margin: 0 -10px -10px;
    list-style: none;
}

.omega-datepicker-list > li {
    padding: 5px 10px 5px 10px;
    border-top: 1px solid #eaeaea;
}

.omega-datepicker-calendar input {
    width: 280px;
    padding: 0 10px;
    font-size: 12px;
    text-align: right;
}

.omega-datepicker-range-input-group {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.omega-datepicker-calendar .omega-datepicker-range-input-group input {
    width: calc(50% - 3px);
    border-radius: 4px;
    text-align: left;
}

.omega-datepicker-calendar:not(.is-range) .omega-datepicker-range-input-group {
    display: none;
}

.omega-datepicker-calendar.is-range .omega-datepicker-calendar-input {
    display: none;
}

.omega-datepicker-option {
    cursor: pointer;
    outline: none;
}

.omega-datepicker-option.is-active {
    font-weight: 600;
}

.omega-datepicker-option.has-padding {
    padding-bottom: 285px;
}

.omega-datepicker .omega-datepicker-specific-date-container {
    position: absolute;
    top: 50px;
    height: 100px;
}

.omega-datepicker .omega-datepicker-date-range-container {
    position: absolute;
    top: 75px;
    height: 100px;
}

.omega-datepicker .omega-datepicker-calendar-table {
    position: absolute;
    transition: all 0.4s cubic-bezier(0, 0.88, 0.39, 0.67);
    z-index: -10;
    opacity: 0;
}

.omega-datepicker .omega-datepicker-calendar-table.is-open {
    overflow-y: hidden;
    transition: all 0.4s cubic-bezier(0, 0.88, 0.39, 0.67);
    z-index: 5;
    opacity: 1;
}

.omega-datepicker .omega-datepicker-month-list {
    transition: all 0.4s cubic-bezier(0, 0.88, 0.39, 0.67);
    z-index: -10;
    opacity: 0;
}

.omega-datepicker .omega-datepicker-month-list.is-open {
    transition: all 0.4s cubic-bezier(0, 0.88, 0.39, 0.67);
    z-index: 5;
    opacity: 1;
}

.omega-datepicker-month {
    width: 40px;
    width: var(--datepicker-month-button-width);
    height: var(--datepicker-month-button-height);
    margin: 0 4px;
    border: none;
    appearance: none;
    background-color: transparent;
    line-height: var(--datepicker-month-button-height);
    text-align: center;
    text-transform: uppercase;
}

.omega-datepicker-month:hover {
    border: 1px solid var(--omega-primary);
    border-radius: 5px;
}

.omega-datepicker-month-cell {
    position: relative;
}

.omega-datepicker-month-cell.is-selected button,
.omega-datepicker-month-cell.is-selection-start button,
.omega-datepicker-month-cell.is-selection-end button {
    background-color: var(--omega-primary);
    border-radius: 7px;
    color: var(--omega-primary-foreground);
}

.omega-datepicker-year-list {
    position: absolute;
    overflow-y: scroll;
    list-style-type: none;
    top: 80px;
    height: 205px;
    right: 0;
    left: 0;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    background: white;
    transition: all 0.4s cubic-bezier(0, 0.88, 0.39, 0.67);
    opacity: 0;
    z-index: -10;
}

.omega-datepicker-year-list.is-open {
    transition: all 0.4s cubic-bezier(0, 0.88, 0.39, 0.67);
    opacity: 1;
    z-index: 5;
}

.omega-datepicker-year-list-item {
    cursor: pointer;
    text-align: center;
    font-size: medium;
    padding: 5px 0px;
}

.omega-datepicker-year-list-item.is-selected {
    font-weight: 600;
    font-size: x-large;
}

.omega-datepicker-year-list-item:hover {
    background-color: var(--omega-calendar-selection-color);
}
