.omega-alert {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid black;
    border-left-width: 5px;
}

.omega-alert-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
    border: none;
    appearance: none;
    background: none;
    font-weight: bold;
}

.omega-alert-icon {
    display: block;
    width: 50px;
    font-size: 24px;
}

.omega-alert p {
    padding: 0;
    margin: 0;
}

.omega-alert-info {
    border-color: #337ab7;
}

.omega-alert-info .omega-alert-icon {
    color: #337ab7;
}

.omega-alert-success {
    border-color: #2caa55;
}

.omega-alert-success .omega-alert-icon {
    color: #2caa55;
}

.omega-alert-warning {
    border-color: #ff6501;
}

.omega-alert-warning .omega-alert-icon {
    color: #ff6501;
}

.omega-alert-error {
    border-color: #ec3a3b;
}

.omega-alert-error .omega-alert-icon {
    color: #ec3a3b;
}

.omega-alert-header {
    font-size: 18px;
}

.omega-alert-aggressive {
    position: fixed;
    z-index: 1100;
    top: calc(35%);
    left: calc(50% - 87.5px);
    width: 400px;
    height: 175px;
    padding: 15px;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.18);
    color: #3c3c3c;
    font-size: 13px;
    text-align: center;
}

.omega-alert-aggressive-success {
    border: 1px solid #2caa55;
}

.omega-alert-aggressive-error {
    border: 1px solid #ec3a3b;
}

.omega-alert-aggressive-success-text {
    color: #2caa55;
}

.omega-alert-aggressive-error-text {
    color: #ec3a3b;
}

.omega-alert-aggressive-success-icon {
    color: #2caa55;
}

.omega-alert-aggressive-warning-icon {
    color: #ff6501;
}

.omega-alert-aggressive-error-icon {
    color: #ec3a3b;
}

.close-aggressive-alert-button {
    margin-top: -15px;
    margin-left: 369px;
}

.omega-close-alert-button {
    color: darkgrey;
}

i.fa.fa-times.omega-close-alert-button {
    font-size: 1em;
}
