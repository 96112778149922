.omega-button.reject {
    border-color: #b41212;
    background-color: #c51818;
    color: #fff;
}

.omega-button.reject:focus {
    border-color: #981718;
    box-shadow: 0 0 8px #ee3a3b;
}

.omega-button.reject:hover:not([disabled]) {
    border-color: #970809;
    background-color: #d00c0d;
}
