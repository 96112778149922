.omega-lookup-filter {
    width: 100%;
    height: 32px;
    padding: 6px 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    font-size: 13px;
    line-height: 20px;
    transition:
        border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
}

.omega-lookup-filter-container {
    position: relative;
}

.omega-lookup-filter-icon {
    position: absolute;
    top: 8px;
    right: 12px;
    color: #aaa;
}

.omega-lookup-list {
    overflow: auto;
    max-height: calc(100vh - 220px);
    padding: 0;
    margin: 10px 0;
    background: none;
    list-style: none;
}

.omega-lookup-list > li {
    position: relative;
    overflow: auto;
    padding: 15px;
    margin: 5px;
    border: 2px solid #eee;
    border-radius: 5px;
    cursor: pointer;
}

.omega-account-lookup-account-name {
    display: block;
    margin-bottom: 5px;
    color: var(--omega-brand-color);
    font-size: 18px;
    text-decoration: none;
}

.omega-account-lookup-account-type {
    padding-right: 10px;
    border-right: 1px solid #999;
    color: #9b9b9b;
    font-size: 13px;
    text-transform: uppercase;
}

.omega-account-lookup-account-number {
    padding-left: 5px;
    margin: 0;
    font-size: 13px;
}

.omega-account-lookup-account-balance {
    position: absolute;
    top: 50%;
    right: 20px;
    font-size: 20px;
    transform: translateY(-50%);
}

.omega-lookup-list-count {
    padding: 0 5px;
    font-size: 13px;
    font-weight: bold;
}
