.omega-download-bar {
    z-index: 2;
    top: 0;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    align-items: center;
}

.omega-download-bar-button-group {
    display: flex;
    justify-content: flex-end;
}

.download-bar-item {
    display: inline-block;
    font-size: 16px;
    height: 55px;
}

.download-bar-item button {
    width: 44px;
    height: 44px;
}

.omega-download-bar-menu {
    position: relative;
}

.omega-download-dropdown {
    position: absolute;
    top: 46px;
    z-index: 5;
    min-width: 50px;
    padding: 0;
    border: 1px solid #e9e9e9;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    list-style: none;
}

.omega-download-dropdown li {
    min-width: 50px;
    padding: 10px 25px;
    text-align: center;
    color: #0070b7;
    cursor: pointer;
}

.omega-download-dropdown li:hover {
    background-color: #f5f5f5;
}

.omega-download-dropdown a {
    text-decoration: none;
}

.omega-download-bar-actions {
    display: inline;
}

.omega-download-bar-actions i {
    position: absolute;
    margin-top: 18px;
    margin-left: 182px;
    color: darkgrey;
}

.omega-download-save-button {
    height: 25px;
    margin-right: 5px;
    border: 1px solid #337ab7;
    background-color: #337ab7;
    border-radius: 3px;
    color: #fff;
}

.omega-download-cancel-button {
    height: 25px;
    margin-right: 28px;
    border: 1px solid #d3d3d3;
    background-color: white;
    border-radius: 3px;
    color: dimgrey;
}

.omega-save-report {
    overflow: hidden;
    width: 200px;
    height: 25px;
    padding: 8px;
    margin-right: 5px;
    border: 1px solid #337ab7;
    border-radius: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.omega-download-bar.open.dropdown-menu.omega-dropdown {
    margin-left: 190px;
}

button[disabled].omega-download-save-button {
    cursor: not-allowed;
    opacity: 65%;
}

.omega-download-bar-page-title {
    padding-left: 10px;
    font-size: 16px;
    font-weight: 400;
    color: rgb(129, 127, 127);
}

.omega-download-bar-report-title {
    padding-left: 18px;
    font-size: 17px;
    font-weight: 600;
}
