.omega-table th.numeric,
.omega-table td.numeric {
    text-align: right;
}

.omega-table th.date,
.omega-table td.date {
    text-align: center;
}

.omega-table th.time,
.omega-table td.time {
    text-align: center;
}

.omega-table th.dateTime,
.omega-table td.dateTime {
    text-align: center;
}

.omega-table th.userIdentification,
.omega-table td.userIdentification {
    text-align: left;
}

.omega-table th.userType,
.omega-table td.userType {
    text-align: center;
}

.omega-table th.enum,
.omega-table td.enum {
    text-align: center;
}

.omega-table .negative {
    color: #c51012;
}
