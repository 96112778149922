.omega-file-format-container {
    background: #f1f0f0;
    padding: 1px 0 1px 0;
    border-radius: 4px;
    margin-bottom: 60px;
}

.omega-file-format-container label {
    font-weight: 400;
}

.omega-file-format-input-row {
    margin: 15px 0;
}

.omega-file-format-input {
    display: block;
}

.omega-file-format-input-invalid {
    border: 1px solid #c10f00 !important;
    background-color: rgba(204, 16, 16, 0.03) !important;
    box-shadow: 0 0 0 1px rgb(193, 15, 0, 0.2) !important;
    transition: all 200ms cubic-bezier(0, 0, 0.29, 0.75) !important;
}

.omega-input-invalid-text {
    color: #c10f00;
}

.omega-file-format-input-wrapper {
    height: 60px;
    width: 225px;
    margin: 15px 5px;
    align-items: center;
}

.omega-file-format-container section {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin: 10px;
    padding: 10px;
}

.omega-file-format-title-section {
    min-height: 72px;
}

.omega-file-format-title-section h2 {
    margin: 0;
}

.omega-file-format-section-header p {
    display: inline-block;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 400;
}

.omega-file-format-navigation {
    position: relative;
    bottom: -10px;
    display: flex;
}

.omega-file-format-navigation label {
    padding: 5px;
    margin: 3px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 125px;
}

/* TABS */
.omega-tabs {
    display: flex;
}

.omega-tab {
    text-align: center;
    font-weight: 600;
    padding: 5px;
    margin: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #0070b7;
}

.omega-tab .page-tab {
    max-width: 100px;
}

.omega-tab.active-tab {
    border-bottom: 2px solid #0070b7;
}

.omega-tab-content {
    display: none;
}

.omega-tab-content.show-tab-content {
    display: block;
    border-top: 1px solid #d4d2d2;
}

/* TOOLTIP */
.omega-file-format-icon {
    font-size: 16px;
    color: #929090;
    margin-left: 5px;
    margin-top: 8px;
}

.omega-tooltip {
    position: relative;
    white-space: nowrap;
    height: 32px;
    min-width: 25px;
}

.omega-tooltip:after {
    text-align: left;
    white-space: normal;
}

.omega-tooltip:focus {
    outline: none;
}

.omega-tooltip::before,
.omega-tooltip::after {
    left: 50%;
    opacity: 0;
    position: absolute;
    z-index: -100;
}

.omega-tooltip:hover::before,
.omega-tooltip:focus::before,
.omega-tooltip:hover::after,
.omega-tooltip:focus::after {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 100;
}

.omega-tooltip::before {
    border-style: solid;
    border-width: 1em 0.75em 0 0.75em;
    border-color: #3e474f transparent transparent transparent;
    bottom: 140%;
    content: '';
    margin-left: -0.5em;
    transition:
        all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26),
        opacity 0.65s 0.5s;
    transform: scale(0.6) translateY(-90%);
}

.omega-tooltip:hover::before,
.omega-tooltip:focus::before {
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.65, 1.13) 0.2s;
}

.omega-tooltip::after {
    background: #3e474f;
    border-radius: 0.25em;
    bottom: 180%;
    color: #edeff0;
    content: attr(data-message);
    margin-left: -8.75em;
    padding: 1em;
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
    transform: scale(0.6) translateY(50%);
    width: 17.5em;
}

.omega-tooltip:hover::after,
.omega-tooltip:focus::after {
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
}

.omega-file-format-name-section {
    display: flex;
    flex-wrap: wrap;
    min-height: 100px;
    align-items: center;
}

.omega-file-format-name-section span {
    margin-left: 30px;
    margin-top: 20px;
}

.omega-file-format-options-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.omega-file-format-input {
    width: 85% !important;
}

/* RADIOS */

.omega-radio-group {
    width: 225px;
}

.omega-radio-wrapper {
    display: inline-block;
}

.omega-radio-wrapper * {
    cursor: pointer;
}

.omega-radio-wrapper [type='radio']:checked,
.omega-radio-wrapper [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}

.omega-radio-wrapper [type='radio']:checked + label,
.omega-radio-wrapper [type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

.omega-radio-wrapper [type='radio']:checked + label:before,
.omega-radio-wrapper [type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}

.omega-radio-wrapper [type='radio']:checked + label:after,
.omega-radio-wrapper [type='radio']:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: var(--omega-primary);
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.omega-radio-wrapper [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.omega-radio-wrapper [type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.omega-file-format-select {
    position: relative;
    width: 100%;
    height: 32px;
    padding: 6px 10px;
    margin-bottom: 3px;
    border: 1px solid rgb(199, 199, 199);
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: var(--omega-dropdown-arrow) right 4px center no-repeat #fff;
    background-size: 12px 12px;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition:
        border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
}

.omega-file-format-select::after {
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 0;
    height: 0;
    margin-top: -2px;
    border-width: 6px 6px 0;
    border-style: solid;
    border-color: #4b4742 transparent;
    content: '';
}

.omega-file-format-column-order-section-header {
    display: flex;
    justify-content: space-between;
}
.omega-file-format-column-order-section-title {
    font-size: 16px;
}

.omega-file-format-column-glossary {
    position: relative;
}

.omega-file-format-column-glossary button {
    font-size: 14px;
    color: var(--omega-primary);
    border: none;
    background: none;
    padding: 0;
}

#glossary-divider {
    border-right: 1px solid rgb(197, 190, 190);
    margin: 0 5px;
    height: 20px;
}

/* LEGEND */
.omega-file-format-legend {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 25px;
}

.omega-file-format-legend:focus {
    outline: none;
}

.omega-file-format-legend-detail {
    position: absolute;
    bottom: -15px;
    right: -115px;
    width: 200px;
    background: #fff;
    border: 1px solid rgba(0, 101, 207, 0.5);
    box-shadow: 0 0 10px 0 rgba(57, 57, 57, 0.5);
    border-radius: 4px;
    padding: 5px 11px 15px 11px;
    opacity: 0;
    z-index: -10;
    transform: translate(-50%, -50%) rotateX(-180deg);
    transition: all 0.25s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.omega-file-format-legend-detail.show {
    opacity: 1;
    z-index: 100;
    transform: translate(-50%, -50%) rotateX(0deg);
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.omega-file-format-legend-detail-header {
    display: flex;
    justify-content: flex-end;
    color: rgb(168, 157, 157);
    font-size: 14px;
    margin-bottom: 3px;
}

.swatch {
    position: relative;
    display: inline-block;
    height: 11px;
    width: 11px;
    border-radius: 50%;
}

.payment-information-swatch {
    background-color: #19c466;
}

.beneficiary-information-swatch {
    background-color: #00bde9;
}

.domestic-intermediary-swatch {
    background-color: #a5a5a5;
}

.omega-file-format-legend .domestic-intermediary-swatch {
    top: 12px;
    right: 22px;
}

.omega-action-bar {
    position: fixed;
    left: 200px; /* only for the demo bc of nav sidebar - should remove this from here and stick in example*/
    bottom: 0;
    height: 55px;
    width: 100%;
    background: white;
    border-top: 1px solid #e0e0e0;
}

/* GLOSSARY (MODAL) */
.omega-modal {
    position: fixed;
    box-shadow: 0px 0px 8px 0px #333;
    text-align: left;
    background: #fff;
    padding: 5px 20px;
}

.omega-modal-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(9, 17, 9, 0.75);
    opacity: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transition: all 0.5s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.omega-modal-background.show {
    display: block;
    opacity: 1;
    z-index: 10;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.omega-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.omega-modal-header-title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #5a5b5a;
    font-weight: 600;
}

.omega-modal-header omega-button {
    flex-shrink: 2;
}

#omega-file-format-glossary {
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: all 0.5s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    height: 400px;
    width: 525px;
    border-radius: 4px;
}

#omega-file-format-glossary.show {
    z-index: 11;
    transform: translate(-50%, -50%) scale(1);
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#omega-file-format-glossary ul {
    list-style: none;
    padding: 0 10px;
}

#omega-file-format-glossary li {
    margin-top: 5px;
}
