.omega-button.secondary {
    border: 1px solid #337ab7;
    background-color: #fff;
    color: #337ab7;
}

.omega-button.secondary:focus {
    box-shadow: 0 0 6px 0 #66afe9;
}

.omega-button.secondary:hover:not([disabled]) {
    border-color: #204d73;
    color: #204d73;
}

.omega-button.secondary:disabled {
    color: #888;
}
